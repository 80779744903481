* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Pretendard';
}

$hover_color : #c1c1c1;
$white_color : #fff;

@mixin flex_center {
    display: flex;
    align-items: center;
}

@mixin flex_middle {
    display: flex;
    align-items: center;
    justify-content: center;
}

a {
    color: white;
    text-decoration: none;
    transition: 0.3s;
}

a:hover {
    color: $hover_color;
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

/* 모바일 가로, 모바일 세로 (해상도 480px ~ 767px)*/
@media all and (max-width:767px) {
    header {
        width: 100%;
        height: 64px;
        background-color: #000;
        position: fixed;
        z-index: 800;

        .header_container {
            height: 100%;
            flex-direction: row;
            @include flex_center;
            justify-content: space-between;

            .logo_wrap {

                a>img {
                    width: 20%;
                    height: auto;
                }
            }

            .header_right_wrap {

                display: none;
                justify-content: space-around;

                a {
                    color: white;
                    text-decoration: none;
                    transition: 0.3s;
                }

                a:hover {
                    color: $hover_color;
                    text-decoration: none;
                }

                a:active {
                    text-decoration: none;
                }

                a:visited {
                    text-decoration: none;
                }
            }

            .wise_container {
                height: 100%;
                justify-content: flex-end;
                @include flex_center;
                display: none !important;

                a {
                    color: white;
                    text-decoration: none;
                    transition: 0.3s;
                }

                a:hover {
                    color: $hover_color;
                    text-decoration: none;
                }

                a:active {
                    text-decoration: none;
                }

                a:visited {
                    text-decoration: none;
                }

                .contact_wrap {
                    flex-direction: row;
                    @include flex_center;

                    p {
                        margin-left: 10px;
                        color: $white_color;
                        margin-bottom: 0 !important;
                        display: none;
                    }

                    p:hover {
                        color: $hover_color;
                        transition: 0.3s all;
                        cursor: pointer;
                    }
                }

                .contour_line {
                    width: 2px;
                    height: 30%;
                    background-color: $white_color;
                    margin: 0 5px;
                }
            }

            .menu_wrap {
                color: white;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .mobile_menu {
            color: white;
            // border-bottom-left-radius: 20px;
            // border-bottom-right-radius: 20px;
            transition: 0.6s all;
            height: 0;
            overflow: hidden;

            ul:nth-child(1) {
                list-style: none;
                padding: 0 !important;
                margin: 0 !important;
                background-color: #ebebeb;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                li:nth-child(1) {
                    width: 95%;
                    padding-left: 2rem;
                    background-color: #ebebeb;
                    border-bottom: 1px solid #acacac;
                }

                li:nth-child(2) {
                    width: 95%;
                    padding-left: 2rem;
                    background-color: #ebebeb;
                    border-bottom: 1px solid #acacac;
                }

                li:nth-child(3) {
                    width: 95%;
                    padding-left: 2rem;
                }


            }

            ul:nth-child(2) {
                list-style: none;
                padding: 0 !important;
                margin: 0 !important;
                background-color: #dbdbdb;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                li:nth-child(1) {
                    width: 95%;
                    padding-left: 5rem;
                    background-color: #dbdbdb;
                    border-bottom: 1px solid #acacac;

                    img {
                        margin-right: 15px;
                    }
                }

                li:nth-child(2) {
                    width: 95%;
                    padding-left: 5rem;
                    border: none;
                    background-color: #dbdbdb;

                    img {
                        margin-right: 15px;
                    }
                }
            }

            li {
                padding: 16px 50px;
                font-family: Pretendard;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: 0.24px;
                height: auto;
                color: #444444;
            }

            a {

                color: #444444;
                text-decoration: none;
                transition: 0.3s;
            }

            a:hover {
                color: #444444;
                text-decoration: none;
            }

            a:active {
                text-decoration: none;
            }

            a:visited {
                text-decoration: none;
            }
        }
    }

    .video_wrap,
    .notop_video_wrap {
        padding-top: 64px;
        width: 100%;
        position: relative;
        background: #ffffff;
        max-height: 640px;
        height: auto;
        margin-bottom: 40px;

        video {
            width: 100%;
            display: block;
        }
    }

    .main_wrap {
        width: 100%;
        height: auto;
        background-color: $white_color;
        padding-top: 104px;

        .text_wrap {

            font-family: Pretendard;
            font-size: 30px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            padding: 0 36px;

            span {
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.32;
                letter-spacing: normal;
            }

            .intro_text {
                margin-top: 25px;

                pre {
                    font-family: Pretendard;
                    font-size: 15px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.6;
                    letter-spacing: -0.08px;
                    color: #3d3d3d;
                }
            }

            .estimate_wrap {
                width: 100%;
                height: auto;
                text-align: center;

                .img_btn {
                    width: 100%;
                    height: auto;
                    margin-top: 15px;
                }
            }
        }

        .image_wrap {
            position: relative;
            height: 300px;
            padding-top: 20px;

            img:nth-child(1) {
                width: 100%;
                height: auto;
            }

            img:nth-child(2) {
                width: 100%;
                left: -7%;
            }

            img:nth-child(3) {
                z-index: 1000;
                width: 47%;
                top: 0%;
                right: -8%;
            }
        }

        .company_wrap {
            text-align: center;
            padding-bottom: 50px;
            margin-top: 70px;

            .company_intro {
                font-family: Pretendard;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: center;
            }

            .logo_wrap {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 18px;
                background-color: #f8f8f8;
                padding: 27px;

                .company_line {

                    width: 100%;

                    img:nth-child(1) {
                        width: 100%;
                        margin-bottom: 15px;
                    }

                    img:nth-child(2) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .service_wrap {
        width: 100%;
        height: auto;
        background-color: #f5f6f6;
        padding: 62px 19px 0px 19px;

        .service_title {
            font-family: Pretendard;
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.59;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }

        .service_img_wrap {
            margin-top: 42px;

            div {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                margin-bottom: 48px;

                img:nth-child(1) {
                    width: 9%;
                    height: auto;
                    margin-bottom: 30px;
                }

                p {
                    margin-bottom: 18px;
                    font-family: Pretendard;
                    font-size: 16px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.88;
                    letter-spacing: normal;
                    color: #232323;
                    text-align: center;
                }

                img:nth-child(3) {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .netar_wrap {
        width: 100%;
        height: auto;
        background-color: $white_color;
        padding-top: 62px;
        background-image: linear-gradient(to bottom, rgba(222, 228, 255, 0), rgba(222, 228, 255, 0.7) 59%, rgba(222, 228, 255, 0));

        .netar_title {
            font-family: Pretendard;
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.64;
            letter-spacing: normal;
            text-align: center;
            color: #000;
        }

        .netar_img_wrap {
            margin-top: 47px;

            img {
                width: 100%;
                height: auto;
                margin-bottom: 65px;
            }

            div:nth-child(3)>img {
                margin-bottom: 20px !important;
            }
        }
    }

    .netar_client_wrap {
        padding-top: 30px;
        background-image: linear-gradient(to top, #cbd8eb, rgba(222, 236, 255, 0) 100%, #dee4ff);

        .client_title {
            font-family: Pretendard;
            font-size: 22px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.59;
            letter-spacing: normal;
            text-align: center;
            color: #000;
        }

        span {
            margin-top: 21px;
            font-family: Pretendard;
            font-size: 12px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: #737373;
        }

        .client_img_wrap {
            margin-top: 60px;

            .row>div {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                img {
                    width: 100%;
                    height: auto;
                    margin-bottom: 30px;
                }
            }
        }

        .browser_wrap {
            padding-top: 77px;
            text-align: center;
            padding-bottom: 69px;

            .slide_wrap {
                //width: 50%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow-x: hidden;
            }

            .browser_line {
                display: flex;
                align-items: center;

                img {
                    margin-right: 5px;
                }
            }
        }

        .wait_wrap {
            margin-top: 50px;
            display: flex;
            justify-content: center;

            .proposal_wrap {

                width: 100%;
                text-align: right;
                display: none;

                h4 {
                    font-family: Pretendard;
                    font-size: 28px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    color: #3340ff;
                    text-align: right;
                }

                p {
                    font-family: Pretendard;
                    font-size: 15px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.44;
                    letter-spacing: normal;
                    color: #828282;
                    text-align: right;
                    margin-bottom: 0 !important;

                    span:nth-child(1) {
                        font-size: 15px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.44;
                        letter-spacing: normal;
                        color: #000;
                    }

                    span:nth-child(2) {
                        font-size: 15px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.44;
                        letter-spacing: normal;
                        color: #3340ff;
                    }
                }

                .border_div {
                    width: 55%;
                    border-bottom: 2px solid #5472ff;
                    margin-top: 15px;
                    float: right;
                }

                .pageone_wrap {
                    display: none;
                    margin-top: 30px;
                    height: 30px;
                    line-height: 28px;
                    padding-bottom: 100px;

                    img:nth-child(1) {
                        margin-right: 15px;
                        cursor: pointer;
                    }

                    img:nth-child(2) {
                        position: relative;
                        top: -3px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    footer {
        height: auto;
        background-color: #000;
        padding: 28px 0 0 0;

        .temp_class {

            display: flex;
            justify-content: flex-end;

            .footer_nav_wrap {

                width: 50%;
                color: #C1C1C1;
                display: none;

                .text_wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 130px;
                    height: 30px;

                    span:nth-child(1) {
                        color: #fff;
                    }

                    .contour_line {
                        width: 2px;
                        height: 50%;
                        background-color: #fff;
                    }

                    .icon_wrap {
                        width: 15%;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                    }
                }
            }
        }

        .copyright_wrap {
            padding: 26px 17px 28px 17px;

            p:nth-child(1) {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                cursor: pointer;
                display: inline-block;
                margin-bottom: 0 !important;
            }

            p:nth-child(2) {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                margin-top: 26px;
            }

            p:nth-child(3) {
                margin-top: 17px;
                opacity: 0.6;
                font-family: OpenSans;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: italic;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
            }

            p:nth-child(4) {
                font-family: Pretendard;
                font-size: 11px;
                font-weight: 200;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: 0.11px;
                text-align: left;
                color: #fff;
                margin-top: 18px;
            }
        }
    }

    .padding_map_wrap,
    .map_wrap {
        width: 100%;
        height: 400px;
        background-color: #C1C1C1;
        margin-top: 64px;
        position: relative;
        cursor: pointer;

        svg {
            font-size: 1.5rem;
            color: red;
        }
    }

    .padding_map_wrap::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(0, 0, 0, 0.05);
    }

    .contactus_wrap {
        width: 100%;
        height: auto;
        background-color: #fff;
        padding: 55px 0 0 0;

        h3 {
            margin-left: 30px;
            font-family: Pretendard;
            font-size: 32px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.81;
            letter-spacing: normal;
            padding-left: 0 !important;
        }

        .address_wrap {
            padding: 41px 30px 0 30px;

            p:nth-child(1) {
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
            }

            p:nth-child(3) {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: 0.40px;
                color: #3b3b3b;
            }

            address {
                font-family: Pretendard;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: 0.24px;
            }
        }

        .address_wrap2 {
            padding: 41px 30px 147px 30px;

            p:nth-child(1) {
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
            }

            p:nth-child(3) {
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
            }

            address {
                font-family: Pretendard;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: 0.24px;
            }

            span {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                color: #2f83ff;
            }
        }
    }
}