* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Pretendard';
}

$hover_color : #c1c1c1;
$white_color : #fff;

@mixin flex_center {
    display: flex;
    align-items: center;
}

@mixin flex_middle {
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes motion {
    0% {
        margin-top: 0px;
    }

    100% {
        margin-top: 10px;
    }
}

@keyframes anime_textup {
    0% {
        top: 0;
    }

    20% {
        top: -1rem;
    }

    40% {
        top: 0
    }

    60% {
        top: 0
    }

    80% {
        top: 0
    }

    100% {
        top: 0
    }
}

a {
    color: white;
    text-decoration: none;
    transition: 0.3s;
}

a:hover {
    color: $hover_color;
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

.App {
    width: 100%;
    height: auto;
    overflow-x: hidden;
}

/* PC (해상도 1024px)*/
@media all and (min-width:1400px) {
    .view_header {
        width: 100%;
        height: 64px;
        background-color: #000;
        position: fixed;
        z-index: 1050;
        transition: 0.3s all;

        .header_container {
            height: 100%;
            flex-direction: row;
            @include flex_center;
            justify-content: space-between;

            .logo_wrap>a>img {
                width: 82px;
                height: auto;
            }

            .header_right_wrap {

                width: 25%;
                color: #C1C1C1;

                .text_wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 30px;

                    span {
                        cursor: pointer;
                        transition: 0.3s all;
                    }

                    span>a {
                        color: $hover_color;
                        text-decoration: none;
                        transition: 0.3s;
                    }

                    span>a:hover {
                        color: $white_color;
                        text-decoration: none;
                    }

                    span>a:active {
                        text-decoration: none;
                    }

                    span>a:visited {
                        text-decoration: none;
                    }

                    span:hover {
                        color: $white_color;
                    }

                    .contour_line {
                        width: 2px;
                        height: 50%;
                        background-color: #fff;
                    }

                    .icon_wrap {
                        width: 25%;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        img {
                            cursor: pointer;
                        }
                    }
                }
            }

            .wise_container {
                height: 100%;
                justify-content: flex-end;
                @include flex_center;

                a {
                    color: white;
                    text-decoration: none;
                    transition: 0.3s;
                }

                a:hover {
                    color: $hover_color;
                    text-decoration: none;
                }

                a:active {
                    text-decoration: none;
                }

                a:visited {
                    text-decoration: none;
                }

                .contact_wrap {
                    flex-direction: row;
                    @include flex_center;

                    p {
                        margin-left: 10px;
                        color: $white_color;
                        margin-bottom: 0 !important;
                    }

                    p:hover {
                        color: $hover_color;
                        transition: 0.3s all;
                        cursor: pointer;
                    }
                }

                .contour_line {
                    width: 2px;
                    height: 30%;
                    background-color: $white_color;
                    margin: 0 30px;
                }

                .blog_wrap {
                    flex-direction: row;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    p {
                        margin-left: 10px;
                        color: $white_color;
                        margin-bottom: 0 !important;
                    }
                }
            }

            .menu_wrap {
                display: none;
            }
        }

        .mobile_menu {
            display: none;
        }
    }

    header>ul {
        display: none;
    }

    .hide_header {
        height: 0;
        transition: 0.3s all;

        .header_container {
            height: 100%;
            flex-direction: row;
            @include flex_center;
            justify-content: space-between;

            .header_right_wrap {

                width: 40%;
                color: #C1C1C1;

                .text_wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 30px;

                    span {
                        cursor: pointer;
                        transition: 0.3s all;
                    }

                    span>a {
                        color: $hover_color;
                        text-decoration: none;
                        transition: 0.3s;
                    }

                    span>a:hover {
                        color: $white_color;
                        text-decoration: none;
                    }

                    span>a:active {
                        text-decoration: none;
                    }

                    span>a:visited {
                        text-decoration: none;
                    }

                    span:hover {
                        color: $white_color;
                    }

                    .contour_line {
                        width: 2px;
                        height: 50%;
                        background-color: #fff;
                    }

                    .icon_wrap {
                        width: 15%;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        img {
                            cursor: pointer;
                        }
                    }
                }
            }

            .wise_container {
                height: 100%;
                justify-content: flex-end;
                @include flex_center;

                a {
                    color: white;
                    text-decoration: none;
                    transition: 0.3s;
                }

                a:hover {
                    color: $hover_color;
                    text-decoration: none;
                }

                a:active {
                    text-decoration: none;
                }

                a:visited {
                    text-decoration: none;
                }

                .contact_wrap {
                    flex-direction: row;
                    @include flex_center;

                    p {
                        margin-left: 10px;
                        color: $white_color;
                        margin-bottom: 0 !important;
                    }

                    p:hover {
                        color: $hover_color;
                        transition: 0.3s all;
                        cursor: pointer;
                    }
                }

                .contour_line {
                    width: 2px;
                    height: 30%;
                    background-color: $white_color;
                    margin: 0 30px;
                }

                .blog_wrap {
                    cursor: pointer;
                }
            }

            .menu_wrap {
                display: none;
            }
        }
    }

    .temp_bg {
        width: 100%;
        height: 985px;
        background-color: #f6f6f6;
        position: absolute;
    }

    .video_wrap {
        margin-top: 64px;
        width: 100%;
        position: relative;
        min-height: 975px;
        transition: 0.3s all;
        padding: 93px 0 109px 0;
        margin-left: 50px;

        .video_frame {

            width: 71%;
            height: 71%;
            border-radius: 9px;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
            border: solid 2px rgba(198, 204, 210, 0.73);
            background-color: #fff;
            padding: 19px;
            position: relative;
            z-index: 300;

            video {
                width: 100%;
                height: auto;
                display: block;
                height: auto;
            }
        }

        img:nth-child(2) {
            position: absolute;
            top: 24%;
            right: 120px;
            z-index: 200;
        }

        img:nth-child(3) {
            margin-top: 63px;
        }

        .video_text_wrap {
            width: auto;
            height: auto;
            padding: 10px 0 0 45px;
        }

        .video_text_wrap>p:nth-child(1) {
            font-family: Pretendard;
            font-size: 36px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.18px;
            color: #303030;
            margin-bottom: 0 !important;
        }

        .video_text_wrap>p:nth-child(2) {
            font-family: Pretendard;
            font-size: 39px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.2px;
            text-align: left;
            color: #303030;
            margin-bottom: 0 !important;
        }
    }

    .notop_video_wrap {
        padding-top: 0px;
        width: 100%;
        position: relative;
        background: #ffffff;
        min-height: 640px;
        transition: 0.3s all;

        video {
            width: 1320px;
            display: block;
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
        }
    }

    .main_wrap {
        width: 100%;
        height: auto;
        background-color: $white_color;

        .text_wrap {
            padding: 132px 0 0 100px;

            span {
                font-size: 44px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.32;
                letter-spacing: normal;
            }

            .intro_text {
                margin-top: 25px;

                pre {
                    font-family: Pretendard;
                    font-size: 20px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.4;
                    letter-spacing: -0.1px;
                    color: #3d3d3d;
                }
            }

            .img_btn {
                margin-top: 35px;
                position: relative;
                z-index: 1000;
                cursor: pointer;
            }
        }

        .image_wrap {
            position: relative;
            height: 600px;

            img {
                position: absolute;
            }

            img:nth-child(1) {
                left: 28%;
                top: 84px;
                z-index: 1000;
            }

            img:nth-child(2) {
                left: 18%;
                bottom: 16%;
            }

            img:nth-child(3) {
                right: 6%;
                top: -8%;
            }
        }

        .company_wrap {
            padding-top: 51px;
            text-align: center;
            padding-bottom: 149px;

            .company_intro {
                font-family: Pretendard;
                font-size: 36px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: center;
            }

            .logo_wrap {
                //width: 50%;
                height: 111px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 79px;
                overflow-x: hidden;
            }

            .company_line {
                display: flex;
                align-items: center;

                img {
                    margin-right: 100px;
                }
            }
        }
    }

    .service_wrap {
        width: 100%;
        height: auto;
        background-color: #f5f6f6;
        padding: 112px 0 83px 0;

        .service_title {
            font-family: Pretendard;
            font-size: 36px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: center;
        }

        .service_img_wrap {
            margin-top: 42px;

            .first_box {
                display: flex;
                justify-content: end;
            }

            .third_box {

                margin-top: 15px;

                img {
                    float: right;
                }
            }

            .forth_box {
                margin-top: -100px;
            }
        }
    }



    .netar_wrap {
        width: 100%;
        height: auto;
        background-color: $white_color;
        padding-top: 112px;
        padding-bottom: 194px;

        .netar_title {
            font-family: Pretendard;
            font-size: 36px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: center;
        }

        .netar_img_wrap {
            height: 760px;
            margin-top: 47px;

            div>img {

                position: relative;
                animation: anime_textup 1.5s infinite;
            }

            .first_netar {
                margin-top: 46px;

                img {
                    //animation: motion 0.9s linear 0s infinite alternate; margin-top: 0;
                    width: 100%;
                    animation-delay: .1s;
                }
            }

            .second_netar>img {
                //animation: motion 0.9s linear 0s infinite alternate; margin-top: 0;
                animation-delay: .2s;
                width: 100%;
            }

            .third_netar {
                margin-top: 122px;

                img {
                    //animation: motion 0.9s linear 0s infinite alternate; margin-top: 0;
                    animation-delay: .3s;
                }
            }

            .forth_netar {
                margin-top: -200px;

                img {
                    //animation: motion 0.9s linear 0s infinite alternate; margin-top: 0;
                    width: 100%;
                    animation-delay: .1s;
                }
            }

            .fifth_netar {
                margin-top: -265px;

                img {
                    //animation: motion 0.9s linear 0s infinite alternate; margin-top: 0;
                    width: 100%;
                    animation-delay: .2s;
                }
            }
        }
    }

    .netar_client_wrap {
        padding-top: 100px;
        background-image: linear-gradient(to bottom, rgba(222, 228, 255, 0), rgba(222, 228, 255, 0.56) 70%, #dee4ff);

        .client_title {
            font-family: Pretendard;
            font-size: 36px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.17;
            letter-spacing: normal;
            text-align: left;
        }

        span {
            margin-top: 21px;
            font-family: Pretendard;
            font-size: 14px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: #737373;
        }

        .client_img_wrap {
            margin-top: 60px;

            .first_box {

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .second_box {

                img {
                    width: 100%;
                    height: auto;
                }

                margin-top: -78px;
            }

            .third_box {
                display: flex;
                justify-content: center;
                margin-top: 53px;
                position: relative;

                img:nth-child(1) {
                    position: absolute;
                    left: 2%;
                    top: 15%;
                    z-index: 900;
                }

                img:nth-child(2) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .wait_wrap {
            margin-top: 205px;
            display: flex;
            justify-content: center;

            .proposal_wrap {

                width: 1113px;
                text-align: right;

                h4 {
                    font-family: Pretendard;
                    font-size: 28px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    color: #3340ff;
                    text-align: right;
                }

                p {
                    font-family: Pretendard;
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.44;
                    letter-spacing: normal;
                    color: #828282;
                    text-align: right;
                    margin-bottom: 0 !important;

                    span:nth-child(1) {
                        font-size: 18px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.44;
                        letter-spacing: normal;
                        color: #000;
                    }

                    span:nth-child(2) {
                        font-size: 18px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.44;
                        letter-spacing: normal;
                        color: #3340ff;
                    }
                }

                .border_div {
                    width: 42%;
                    border-bottom: 2px solid #5472ff;
                    margin-top: 23px;
                    float: right;
                }

                .pageone_wrap {
                    margin-top: 62px;
                    height: 30px;
                    line-height: 28px;
                    padding-bottom: 152px;

                    img:nth-child(1) {
                        margin-right: 15px;
                        cursor: pointer;
                    }

                    img:nth-child(2) {
                        position: relative;
                        top: -3px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    footer {
        height: auto;
        background-color: #000;
        padding: 57px 0 0 0;

        .temp_class {

            display: flex;
            justify-content: flex-end;

            .footer_nav_wrap {

                width: 50%;
                color: #C1C1C1;

                .text_wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 130px;
                    height: 30px;

                    span {
                        cursor: pointer;
                        transition: 0.3s all;
                    }

                    span>a {
                        color: $hover_color;
                        text-decoration: none;
                        transition: 0.3s;
                    }

                    span>a:hover {
                        color: $white_color;
                        text-decoration: none;
                    }

                    span>a:active {
                        text-decoration: none;
                    }

                    span>a:visited {
                        text-decoration: none;
                    }

                    span:hover {
                        color: $white_color;
                    }

                    .contour_line {
                        width: 2px;
                        height: 50%;
                        background-color: #fff;
                    }

                    .icon_wrap {
                        width: 15%;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        img {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .copyright_wrap {
            padding: 51px 0 94px 17px;

            p:nth-child(1) {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                display: inline-block;
                cursor: pointer;
                margin-bottom: 0 !important;
            }

            p:nth-child(2) {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
                margin-top: 26px;
            }

            p:nth-child(3) {
                margin-top: 17px;
                opacity: 0.6;
                font-family: OpenSans;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: italic;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: #fff;
            }

            p:nth-child(4) {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 200;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: 0.11px;
                text-align: left;
                color: #fff;
                margin-top: 18px;
            }
        }
    }

    .map_wrap {
        width: 100%;
        height: 491px;
        background-color: #C1C1C1;

        // svg {
        //     font-size: 1.5rem;
        //     color: red;
        // }
    }

    .padding_map_wrap,
    .map_wrap {
        width: 100%;
        height: 491px;
        background-color: #C1C1C1;
        margin-top: 64px;
        position: relative;
        cursor: pointer;

        svg {
            font-size: 1.5rem;
            color: red;
        }
    }

    .padding_map_wrap::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(0, 0, 0, 0.05);
    }

    .contactus_wrap {
        width: 100%;
        height: auto;
        background-color: #fff;
        padding: 55px 0 0 0;

        h3 {
            margin-left: 106px;
            font-family: Pretendard;
            font-size: 32px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.81;
            letter-spacing: normal;
            padding-left: 0 !important;
        }

        .address_wrap {
            padding: 41px 0 97px 106px;
            position: relative;

            p:nth-child(1) {
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
            }

            p:nth-child(3) {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: 0.21px;
                color: #3b3b3b;
            }

            address {
                font-family: Pretendard;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: 0.24px;
            }
        }

        .address_wrap:after {
            content: '';
            height: 204px;
            width: 1px;
            position: absolute;
            right: 0;
            top: 45px;
            background-color: #C1C1C1;
        }

        .address_wrap2 {
            padding: 41px 0 97px 106px;

            p:nth-child(1) {
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
            }

            p:nth-child(3) {
                font-family: Pretendard;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: normal;
            }

            address {
                font-family: Pretendard;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.75;
                letter-spacing: 0.24px;
            }

            span {
                font-family: Pretendard;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: normal;
                color: #2f83ff;
            }
        }
    }
}

#map {
    position: relative;
}

#map:after {
    width: 22px;
    height: 40px;
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -11px;
    background: url('https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi_hdpi.png');
    background-size: 22px 40px;
    /* Since I used the HiDPI marker version this compensates for the 2x size */
    pointer-events: none;
    /* This disables clicks on the marker. Not fully supported by all major browsers, though */
}